//normal
@font-face {
  font-family: OpenSans;
  src: url('./OpenSans-Regular.ttf');
  font-weight: normal;
  font-weight: 400;
}

//italic
@font-face {
  font-family: OpenSans;
  src: url('./OpenSans-Italic.ttf');
  font-style: italic;
  font-weight: normal;
  font-weight: 400;
}

//light
@font-face {
  font-family: OpenSans;
  src: url('./OpenSans-Light.ttf');
  font-weight: 300;
}

//light italic
@font-face {
  font-family: OpenSans;
  src: url('./OpenSans-LightItalic.ttf');
  font-style: italic;
  font-weight: 300;
}

//semi-bold
@font-face {
  font-family: OpenSans;
  src: url('./OpenSans-SemiBold.ttf');
  font-weight: 600;
}

//semi-bold italic
@font-face {
  font-family: OpenSans;
  src: url('./OpenSans-SemiBoldItalic.ttf');
  font-style: italic;
  font-weight: 600;
}

//bold
@font-face {
  font-family: OpenSans;
  src: url('./OpenSans-Bold.ttf');
  font-weight: bold;
  font-weight: 700;
}

//bold italic
@font-face {
  font-family: OpenSans;
  src: url('./OpenSans-BoldItalic.ttf');
  font-style: italic;
  font-weight: bold;
  font-weight: 700;
}

//extra-bold
@font-face {
  font-family: OpenSans;
  src: url('./OpenSans-ExtraBold.ttf');
  font-weight: 800;
}

//extra-bold italic
@font-face {
  font-family: OpenSans;
  src: url('./OpenSans-ExtraBoldItalic.ttf');
  font-style: italic;
  font-weight: 800;
}
