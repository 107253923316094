@import 'w3';
@import '../fonts/opensans/opensans.scss';

html,
body {
  font-size: 14px;
  font-family: OpenSans, sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: OpenSans, sans-serif !important;
}

.paddingtop {
  padding-top: 24px;
}

.link {
  color: #666666;
  cursor: pointer;
  text-decoration: none;
}

.link:hover {
  color: #bc0031;
}

.headerimage {
  position: absolute;
  text-align: center;
  width: 100%;
  top: 120px;
  z-index: -1;
}

.headerbar {
  background-color: #333333;

  .link,
  .link:hover {
    color: #999999 !important;
  }
}

.headerbar2 {
  background-color: rgba(0, 0, 0, 0.48);

  .link {
    color: #fff !important;
  }

  .link:hover {
    color: #fff !important;
    text-decoration: underline;
  }
}

.middle {
  vertical-align: middle !important;
}

.italic {
  font-style: italic;
}

.col40 { width: 40%; }
.col60 { width: 60%; }

.buttonwidth {
  display: inline-block;
  width: 88px;
}
